import {
  SortDirection,
} from '@mesa-labs/mesa-api/dist/types';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import {
  CardContainer,
  DownloadButton,
  LoadingScreen,
  Pagination,
  PaginationDropdown,
  ResourceSection,
  Table,
  Typography,
} from '@mesa-labs/mesa-ui';
import ResourceCard, { ResourceItemProp } from '@mesa-labs/mesa-ui/dist/components/ResourceCard';
import { ColumnProp } from '@mesa-labs/mesa-ui/dist/components/Table';

import { DefaultPaginationDropdownLimits } from '@mesa-labs/mesa-ui/dist/components/Pagination';
import {
  useGetExternalVendorAsFacilitatorQuery,
  useGetAllInvoicesAsFacilitatorQuery,
  exportAllInvoicesAsFacilitator,
  asExportFilter,
} from '../../redux/api/facilitators';
import { useDispatch, useSelector } from '../../redux/hooks';
import {
  updateInvoicesLimit, updateInvoicesPage, updateInvoicesSortDirection, updateInvoicesSortField, updateInvoicesTotalPages,
} from '../../redux/slices/vendors';

const VendorPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const InvoiceDetailsPageControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const PaginationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 12px 12px 0 12px;
`;

const VendorItems: (string | ResourceItemProp)[] = [
  { key: 'externalVendorId', label: 'Supplier Id' },
  {
    key: 'createdAt', label: 'Enrollment Date', tooltip: 'Date on which supplier signed up for Mesa.', type: 'date',
  },
  {
    key: 'activatedAt', label: 'Enablement Date', tooltip: 'Date on which supplier\'s invoices were enabled for funding by Mesa.', type: 'date',
  },
  { key: 'programCode', label: 'Program' },
  { key: 'registeredAgent', label: 'Supplier Contact' },
  { key: 'registeredAddress', label: 'Address' },
  { key: 'serviceType', label: 'Service Type' },
  { key: 'diverse', label: 'Diverse?' },
];

const InvoiceColumns: ColumnProp[] = [
  {
    key: 'externalDocumentId',
    sortField: 'externalDocumentId',
    label: 'Document Number',
    width: '12%',
  },
  {
    key: 'externalVendorId',
    sortField: 'externalVendorId',
    label: 'Supplier Id',
    width: '12%',
  },
  {
    key: 'externalClientId',
    sortField: 'externalClientId',
    label: 'Company Number',
    width: '12%',
  },
  {
    key: 'invoiceDate',
    sortField: 'invoiceDate',
    label: 'Invoice Date',
    width: '15%',
    type: 'date',
  },
  {
    key: 'invoiceNumber',
    sortField: 'invoiceNumber',
    label: 'Invoice Number',
    width: '12%',
  },
  {
    key: 'totalOpenAmount',
    sortField: 'totalOpenAmount',
    label: 'Invoice Amount',
    width: '12%',
    type: 'currency',
  },
  {
    key: 'estimatedPaymentDate',
    sortField: 'eligibleAt',
    label: 'Payment Date',
    width: '15%',
    type: 'date',
  },
  {
    key: 'displayState',
    sortField: 'state',
    label: 'State',
    width: '10%',
    type: 'status',
  },
];

const partnerId = 1; // TODO

function VendorPage(): React.ReactElement | null {
  const dispatch = useDispatch();
  const { id: externalVendorId } = useParams();
  const accessToken = useSelector((state) => state.auth.accessToken);
  const invoicesSortField = useSelector((state) => state.vendors.invoicesSortField);
  const invoicesSortDirection = useSelector((state) => state.vendors.invoicesSortDirection);
  const invoicesPage = useSelector((state) => state.vendors.invoicesPage) || 1;
  const invoicesTotalPages = useSelector((state) => state.vendors.invoicesTotalPages);
  const invoicesLimit = useSelector((state) => state.vendors.invoicesLimit) || DefaultPaginationDropdownLimits[0];

  const {
    data: vendor,
  } = useGetExternalVendorAsFacilitatorQuery({
    accessToken: accessToken!,
    partnerId: partnerId!,
    externalVendorId: externalVendorId!,
  }, { skip: !partnerId || !externalVendorId });

  const invoiceFilter = useMemo(() => ({
    vendorId: vendor?.internalVendorId || '',
    sortField: invoicesSortField,
    sortDirection: invoicesSortDirection,
    page: invoicesPage,
    limit: (typeof invoicesLimit === 'number' ? invoicesLimit : invoicesLimit.value),
  }), [vendor, invoicesSortField, invoicesSortDirection, invoicesPage, invoicesLimit]);

  const {
    data: {
      data: invoices,
      total: totalInvoices,
    } = {},
  } = useGetAllInvoicesAsFacilitatorQuery({
    accessToken: accessToken!,
    partnerId: partnerId!,
    ...invoiceFilter,
  }, { skip: !vendor });

  useEffect(() => {
    if (totalInvoices !== undefined) {
      dispatch(updateInvoicesTotalPages(Math.ceil(totalInvoices / (typeof invoicesLimit === 'number' ? invoicesLimit : invoicesLimit.value))));
    }
  }, [totalInvoices, invoicesLimit]);

  const invoiceRows = useMemo(() => (invoices || []).map((invoice) => ({
    ...invoice,
    currency: 'USD',
  })), [invoices]);

  return (
    <VendorPageContainer>
      <CardContainer>
        {vendor !== undefined && (
          <ResourceCard
            resource={vendor}
            title={vendor.externalVendorName || vendor.externalVendorId}
            items={VendorItems}
            columns={4}
          />
        )}
        {vendor === undefined && (
          <LoadingScreen />
        )}
      </CardContainer>

      <ResourceSection
        title="Invoices (Last 6 Months)"
        controls={(
          <InvoiceDetailsPageControlsContainer>
            <DownloadButton
              text="Download CSV"
              data={() => exportAllInvoicesAsFacilitator(partnerId, asExportFilter(invoiceFilter), accessToken!)}
              fileName="invoices.csv"
            />
          </InvoiceDetailsPageControlsContainer>
        )}
      >
        <Table
          isLoading={invoices === undefined}
          columns={InvoiceColumns}
          rows={invoiceRows}
          currentSortColumn={invoicesSortField}
          currentSortDirection={invoicesSortDirection}
          setSortField={(field: string) => dispatch(updateInvoicesSortField(field))}
          setSortDirection={(direction: SortDirection) => dispatch(updateInvoicesSortDirection(direction))}
          paginationComponent={(
            <PaginationContainer>
              <PaginationDropdown
                selectedLimit={(typeof invoicesLimit === 'number' ? DefaultPaginationDropdownLimits.find((l) => l.value === invoicesLimit) || DefaultPaginationDropdownLimits[0] : invoicesLimit)}
                onChange={(item) => {
                  dispatch(updateInvoicesLimit(item));
                  dispatch(updateInvoicesPage(1));
                }}
              />
              <Typography.BodySmallBold>
                Total of
                {' '}
                {totalInvoices !== undefined ? totalInvoices : '...'}
                {' '}
                Invoice(s)
              </Typography.BodySmallBold>
              <Pagination
                currentPage={invoicesPage}
                totalPages={invoicesTotalPages || 0}
                onPrev={() => {
                  dispatch(updateInvoicesPage(invoicesPage - 1));
                }}
                onNext={() => {
                  dispatch(updateInvoicesPage(invoicesPage + 1));
                }}
              />
            </PaginationContainer>
          )}
        />
      </ResourceSection>
    </VendorPageContainer>
  );
}

export default VendorPage;
